html, body {
	height: 100%;
}
.white {
	color: #ffffff;
}
.orange {
	color: #ff3100;
}
.dark {
	color: #212529;
}
.whitespace {
	margin-top: 50px;
}
.left {
	text-align: left;
}
.title {
	margin-bottom: 20px !important;
}
.showcase {
	margin-top: 50px;
}
.center {
	margin: 0 auto;
}
.MainContainerImg a {
	text-decoration: none;
	color: #ffffff !important;
}
.MainContainerImg .list-group-item {
	background-color: transparent;
	color: #ffffff;
	border-bottom: 1px solid #ffffff;
}
.MainContainerImg button {
	margin: 20px 0;
}
.footer {
	margin-top: 20px;
	padding-bottom: 20px;
}
.footer .nav {
	margin-bottom: 10px;
}
.footer .nav-link {
	padding: 0.5rem 0.5rem;
}
.footer a {
	color: #5e5e5e;
	font-size: 12px;
	text-transform: uppercase;
	text-decoration: none;
}
.footer a:hover {
	color: #1e1e1e;
}
.footer p {
	font-size: 12px;
	color: #5e5e5e;
}
.footer svg {
	color: tomato;
}
.heart svg {
	color: tomato;
}
@media (max-width: 979px) {
	.col-6 {
		width: 100%;
		margin-top: 50px;
	}
	.showcase {
		margin-top: 0;
	}
	.img-sm {
		width: 90%;
	}
	.video {
		width: 100% !important;
	}
	.dept-btn-cont {
		width: 80%;
	}
}
@media (min-width: 979px) {
	.topbtn {
		display: block;
		color: white;
		text-align: center;
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
.MuiFormLabel-root {
	color: #000 !important;
	font-weight: 600 !important;
}
.MuiFormLabel-root span {
	color: rgb(243, 12, 12) !important;
	font-weight: 600 !important;
}
.promo-text {
	-webkit-text-decoration-line: line-through;
	/* Safari */
	text-decoration-line: line-through;
}
.MuiPaper-root.MuiAccordion-root {
	background-color: #bd0c0c !important;
	color: white;
	padding: 0.35rem 0;
	margin-bottom: 0.25rem;
}

.MuiPaper-root.MuiAccordion-root.bg-white {
	background-color: white!important;
	color: #161616 !important;
	padding: 0.35rem 0;
	margin-bottom: 0.25rem;
	align-items: stretch!important;
}
.MuiTypography-h5 {
	font-size: 1.125rem !important;
	margin-bottom: auto !important;
}
.MuiAppBar-positionFixed {
	background-color: rgba (0, 0, 0, 0.87);
	color: white !important;
}
@media (max-width: 600px) {
	.MuiToolbar-regular {
		padding-top: 0.25rem;
	}
	.MuiIconButton-edgeStart {
		margin-top: -1rem;
	}
	.MuiCardMedia-root {
		padding: 0.5rem;
	}
}
.MuiAccordionSummary-expandIconWrapper {
	color: rgba(254, 254, 254, 0.99) !important;
}

.MuiAccordionSummary-expandIconWrapper {
	color: #161616 !important;
}
@media (min-width: 601px) {
	.MuiCardMedia-root {
		padding: 1rem;
	}
	.MuiPaper-root.MuiCard-root {
		align-items: start !important;
	}
	.MuiPaper-root.MuiCard-root.bg-white {
		align-items: stretch !important;
	}
}
.MuiTypography-caption {
	font-size: 1rem !important;
}
.MuiTypography-body1 {
	min-width: 75px;
}
@media (max-width: 360px) {
	.h1, h1 {
		font-size: calc(1.175rem + 1.5vw) !important;
	}
}
@media (max-width: 575px) {
	.MuiPaper-root.MuiCard-root {
		margin-left: auto !important;
		margin-right: auto !important;
	}
}
@media (max-width: 991px) {
	.navbar-brand {
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.OrderHeader .card {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.ContactHeader {
		height: auto;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.PhonerightImg {
		background-size: auto 80%;
	}
	.ContactHeader {
		height: auto;
	}
}
.MuiImageList-root {
	width: auto !important;
}
.MuiCardActions-root {
	float: right !important;
}
.css-yk16xz-control {
	font-size: large !important;
}
.DateInput_input {
	font-size: large !important;
}
.MuiContainer-root {
	margin-top: 2rem;
}
.btn-outline-danger {
	color: #fff !important;
	border-color: #bd0c0c !important;
}
.btn-outline-danger:hover {
	color: #fff !important;
	background-color: #bd0c0c !important;
	border-color: #bd0c0c !important;
}
.btn-outline-danger:active {
	color: #fff !important;
	background-color: #bd0c0c !important;
	border-color: #bd0c0c !important;
}
.MuiSvgIcon-fontSizeSmall {
	font-size: 1.75rem !important;
}
.MuiBox-root {
	font-size: 1.125rem;
	line-height: normal;
}
.MuiButton-label {
	width: max-content !important;
}
.MuiAppBar-positionFixed {
	position: fixed !important;
}
@media (min-width: 600px) {
	.MuiToolbar-regular {
		min-height: 10px !important;
	}
}
.MuiToolbar-regular {
	min-height: 10px !important;
}
@media (max-width: 991px) {
	/* .navbar {
		display: block !important;
	} */
	.navbar-dark .navbar-toggler {
		margin-left: 24px;
	}
	.navbar-collapse {
		padding-left: 24px;
		padding-top: 24px;
	}
}
.navbar-brand {
	padding-left: 24px;
	padding-right: 70px;
}
.gold {
	color: #DE7700;
}
.pac-container {
	z-index: 99999;
}
.gm-style-iw-a {
	z-index: -1 !important;
}
.swal2-container {
	z-index: 999999 !important;
}
#modalAlert .MuiTypography-h6 {
	font-size: 1.75rem !important;
}
#modalAlert .MuiTypography-colorTextSecondary {
	color: rgba(0, 0, 0, 0.74) !important;
}
.MuiTableCell-root {
	border-bottom: 1px solid rgba(224, 224, 224, 0) !important;
}
.MuiPaper-root.MuiCard-root {
	box-shadow: none !important;
}
.MuiPaper-root.MuiCard-root.bg-white {
	box-shadow: 0 2px 4px rgba(0, 0, 0, .1) ! important;
}
.MuiTableCell-root {
	border-bottom: 1px solid rgba(224, 224, 224, 0) !important;
}
.color-scheme {
	background-color: #DE7700 !important;
	color: black !important;
}
.none-link {
	text-decoration: none !important;
}
.red-link {
	color: #bd0c0c !important;
}
.red {
	background-color: #bd0c0c !important;
	padding: 8px !important;
	max-width: fit-content;
	border-radius: 4px;
}
@media (max-width: 650px) {
	#checkoutTable th img {
		display: none !important;
	}
}
@media (min-width: 651px) and (max-width: 799px) {
	#checkoutTable th img {
		width: 100% !important;
	}
}
@media (max-width: 319px) {
	#checkoutTable .MuiTableCell-root {
		display: contents;
		text-align: center;
	}
	#checkoutTable .MuiGrid-justify-content-xs-flex-end {
		justify-content: center;
	}
	#checkoutTable h5 {
		padding-top: 10px;
	}
}
@media (max-width: 319px) {
	#checkoutTotal .MuiTableCell-root {
		display: contents;
		text-align: center;
	}
	#checkoutTotal h5 {
		padding-top: 10px;
	}
}
#checkoutTotal .MuiButtonBase-root.MuiButton-root {
	min-width: 10px !important;
}
.customBg {
	background: #2b5876;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #2b5876, #4e4376);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #2b5876, #4e4376);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */		
	/* background: rgba(0, 0, 0, 0.74); */
	/* background: #24292f; */
	position: fixed;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding-bottom: 30px;
}
.eLoyaltyBg .MuiBox-root {
	background: transparent;
	/* fallback for old browsers */
	/* background: -webkit-linear-gradient(to right, #2b5876, #4e4376); */
	/* Chrome 10-25, Safari 5.1-6 */
	/* background: linear-gradient(to right, #2b5876, #4e4376); */
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */		
}
.formBG .MuiBox-root {
	background: beige;
	padding: 10px;
	border-radius: 5px;
}
.list-group-item ul, li {
    list-style-type: none;
}
#quickmenu .MuiCardHeader-root {
	padding: .5rem !important;
}
#quickmenu .MuiCardMedia-root  {
	padding: .5rem !important;
}
#quickmenu .MuiCardContent-root  {
	padding: .5rem !important;
}
#quickmenu .MuiTypography-h5 {
	font-size: 1.5rem !important;
}
.plzero ol, ul {
	padding-left: 0 !important;
}
.paddingSpecial {
	/* padding-top: 1rem; */
	padding-left: 2rem !important;	
}
.partner-title {
	margin: 0 auto;
}
.HomemadeHeader {
	background-image: url('../images/ContactSliderImg.png');
	height: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #ffffff!important;
	background-attachment: fixed;
	padding: 100px 0 20px 0;    
    margin-top: -120px;    
}
.SpecialsHeader {
	background-image: url('../images/ContactSliderImg.png');
	height: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #ffffff!important;
	background-attachment: fixed;
	padding: 100px 0 20px 0;    
    margin-top: -120px;    
}
.QuickMenuHeader {
	background-image: url('../images/ContactSliderImg.png');
	height: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #ffffff!important;
	background-attachment: fixed;
	padding: 100px 0 20px 0;    
    margin-top: -120px;    
}
.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	min-height: 100vh !important;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
	min-height: 100vh !important;
	width: 100vw;
	margin-left: -1rem;
	-webkit-transition: margin .25s ease-out;
	-moz-transition: margin .25s ease-out;
	-o-transition: margin .25s ease-out;
	transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
	padding: 0.875rem 1.25rem;
	font-size: 1.2rem;
}

#page-content-wrapper {
	min-width: 0;
	width: 100%;
}
.privacy .h3, h3 {
    font-size: calc(1rem + .6vw) !important;
}
@media (min-width: 1200px) {
	.privacy .h3, h3 {
    	font-size: 1.25rem !important;
	}
}
.CookieConsent {
	width: 100% !important;
	height: 100% !important;
	bottom: 10px !important;
	left: 10px !important;
	max-width: 475px !important;
	max-height: 475px !important;
	justify-content: center !important;
	align-items: center !important;	
}

.main-menus:hover div img {
	transform: scale(1.2);
}
.about-right-text {
    /* margin-top: 20px; */
    background: #DE7700;
    box-shadow: 0px 5px 68px 0px rgb(0 21 100 / 10%);
    padding: 15px;
    display: flex;
    margin-bottom: 15px;
}
.core-icon {
	margin-right: 15px;
	text-align: center;
}
.paddingRight15 {
	padding-right: 15px;
}
@media (max-width: 349px) {
	.navbar-brand {
		padding-right: 64px;
	}
}
@media (max-width: 343px) {
	.navbar-brand {
		padding-right: 60px;
	}
}
@media (max-width: 339px) {
	.navbar-brand {
		padding-right: 58px;
	}
}
@media (max-width: 337px) {
	.navbar-brand {
		padding-right: 56px;
	}
}
@media (min-width: 320px) and (max-width: 337px) {
	.navbar-brand {
		padding-right: 56px;
		padding-left: 56px;
	}
}

/* StickyCategoriesNav */
/* RestMenu BlankSpaceTen 451  */

.stickyCategoriesNav {
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%);
    background: #FFFFFF;
    position: sticky !important;
    top: 100px !important;
    z-index: 3;
    padding: 10px;
    margin-bottom: 35px;
}

.stickyCategoriesNav ul{
	display: flex;
    justify-content: stretch;
    margin: 0px 10px;
    gap: 2rem;
    flex-wrap: wrap;
	height: 40px;
	align-items: center;
}

.stickyCategoriesNav li{
	display: flex;
	list-style: none;
}

.stickyCategoriesNav a{
	color: #333333 !important;
	cursor: pointer;
	padding: 5px 0px !important;
	margin: 5px;
}


.stickyCategoriesNav a.active{
	font-weight: bold;
	border-bottom: 3px solid #bd0c0c !important;
	text-decoration: none;
}

.stickyCategoriesNav a:hover{
	font-weight: bold;
	border-bottom: 3px solid #bd0c0c;

}
/* HEADER STICKY */
.navBarKeto {
position: sticky !important;
top: 0px !important;
background: black !important;
z-index: 9;
}
.SingleDatePicker_picker {
    z-index: 19 !important;
}
.slider-caption {
	text-shadow: -1px -1px 0 #000,  
				1px -1px 0 #000,
				-1px  1px 0 #000,
				1px  1px 0 #000;
}