.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-10 {
  margin-top: 20px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
@media (min-width: 1025px) {
  .navbar.bg-dark {
    background: rgba(0, 0, 0, 0.7) !important;
    padding: 5px 100px;
    color: #ffffff !important;
  }
  .carousel {
    margin-top: -140px;
  }
  .navbar-nav {
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    margin-right: 225px;
  }
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1) !important;
  text-transform: uppercase !important;
}
.navbar-dark:hover .navbar-nav:hover .nav-link:hover {
  text-shadow: 2px 2px 10px #ffffff;
}
.App {
  text-align: left;
}
.App-logo {
	/*height: 40vmin;*/
	width: 90px;
	pointer-events: none;
}
/*showcase*/

.showcase {
  margin-top: 50px !important;
}
.showcase a {
  text-decoration: none;
  color: #ffffff;
}
.showcase a:hover {
  text-shadow: 2px 2px 10px #ffffff;
}
.showcase .col-6 {
  margin-bottom: 50px;
}
/*video*/

.video {
  margin-bottom: 50px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
/*Custom.css*/

html,
body {
  height: 100%;
  font-size: 1em !important;
}
.nav-item a:after {
  content: "/";
  padding-left: 10px;
}
.nav-item:last-child a:after {
  content: none;
}
.nav-link:focus {
  color: red !important;
}
.sticky-top {
  position: relative !important;
}
.white {
  color: #ffffff;
}
.orange {
  color: #ff3100;
}
.dark {
  color: #212529;
}
.whitespace {
  margin-top: 50px !important;
}
.left {
  text-align: left;
}
.title {
  margin-bottom: 20px !important;
}
.showcase {
  margin-top: 50px;
}
.MainContainerImg {
	background-image: url(./assets/images/tagline_bg4.png);
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	/*margin-top: -140px;*/
}
.HeaderImg {
  background-image: url("./assets/images/about_us_bg.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
  padding: 100px 0 20px 0;
  margin-top: -140px;
}
.CookieConsent {
  text-align: center !important;
}
.center {
  margin: 0 auto;
  text-align: center !important;
}
.MainContainerImg {
  padding-bottom: 50px;
}
.MainContainerImg h1 {
  color: #ffffff !important;
  margin: 20px 0 20px 0;
}
.MainContainerImg a {
  text-decoration: none;
  color: whitesmoke !important;
}
.MainContainerImg a:hover {
  color: rgba(f, f, f, 0.87) !important;
}
.MainContainerImg .list-group-item {
  background-color: transparent;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.MainContainerImg button {
  margin: 0 !important;
}
/*footer*/

.footer-area {
  background-image: url("./assets/images/footer-bg.jpg");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
}
.footer-area .f-logo {
  padding-bottom: 20px;
}
.footer-area .widget {
  text-align: left !important;
  padding: 10px 0;
}
.footer-area .widget a {
  color: #DE7700;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}
.footer-area .widget a:hover {
  color: #ffffff;
}
.footer-area .f-title span {
  color: #DE7700;
}
.footer {
  margin-top: 20px;
  padding-bottom: 20px;
  text-align: center !important;
}
.footer .nav {
  margin-bottom: 10px;
}
.footer .nav-link {
  padding: 0.5rem 0.5rem;
}
.footer .privacy a {
  color: #ababab;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
}
.heart span {
  color: red;
}
.footer .privacy a:hover {
  color: #ffffff;
}
.footer p {
  font-size: 12px;
  color: #5e5e5e;
}
.footer svg {
  color: tomato;
}
.heart svg {
  color: tomato;
}
@media (max-width:979px) {
	.col-6 {
		width: 100%!important;
		margin-top: 50px;
	}
	.showcase {
		margin-top: 0;
	}
	.img-sm {
		width: 90%;
	}
	.video {
		width: 100%!important;
	}
	.dept-btn-cont {
		width: 90%;
	}
	.img-icon {
		display: none;
	}
	.showcase .col-6 {
		margin-bottom: 10px!important;
	}
	.about-info .col-8 {
		width: 100%!important;
	}
	.inner-about-gallery .float-left {
		float: none;
		width: 100%;
		margin: 0 auto;
	}
	.inner-about-gallery .float-left img {
		width: 80%;
		margin-top: 10px;
	}
}
@media (min-width:979px) {
	.topbtn {
		display: block;
		color: white;
		text-align: center;
		position: absolute;
		top: 29px!important;
		right: 65px!important;
		font-size: 0.875rem;
		max-width: 130px;
	}
}
.MobileContImg {
	background: url('./assets/images/background.png') center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 35px 20px;
	text-align: center !important;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 2147483647;
}
.MobileContImg a {
  text-decoration: none;
}
.our-dept-main {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  transition: 0.5s;
  margin-bottom: 10px;
  background: transparent;
  color: whitesmoke;
}
.dept-front {
  /* background: #fff; */
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  bottom: 0px;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 420px;
  padding: 15px 10px;
  margin: auto;
  box-shadow: 0 0 15px rgb(0 0 0);
}
.dept-front h3 {
  min-width: 183px;
  text-align: left;
  margin-left: 20px;
}
.dept-1 {
  background: linear-gradient(
    36deg,
    rgba(18, 66, 40, 1) 0%,
    rgba(16, 124, 65, 1) 50%,
    rgba(51, 196, 129, 1) 100%
  );
}
.dept-2 {
  background: linear-gradient(
    36deg,
    rgba(0, 93, 232, 1) 0%,
    rgba(44, 138, 254, 1) 50%,
    rgba(63, 169, 245, 1) 100%
  );
}
.dept-3 {
  background: linear-gradient(
    36deg,
    rgba(136, 33, 168, 1) 0%,
    rgba(137, 70, 202, 1) 50%,
    rgba(111, 112, 252, 1) 100%
  );
}
.dept-btn-cont {
  margin: 20px auto !important;
}
/*social connect*/

.social-connect {
  background: #8B2000;
  padding: 50px 0;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}
.social-title {
  margin: 0 auto;
}
.social-icons ul {
  margin-top: 30px;
}
.social-icons ul,
li {
  list-style-type: square;
  padding: 2.5px;
}
.social-icons li {
  vertical-align: middle;
  line-height: 1em;
  display: inline-block !important;
}
.social-icons a {
  padding: 10px 14px;
  color: #8B2000;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 100%;
  font-size: 24px;
}
.social-icons li:hover {
  animation: pulse 2s infinite;
}
.social-icons a:hover {
  color: #8B2000;
}
@keyframes pulse {
  0%,
  100%, padding-left {
    animation-timing-function: ease-in;
  }
  50% {
    transform: scale(1.2);
  }
}
.HeaderImg.row {
  margin-top: 60px;
}
/*discover menu*/

.card-group > div {
  margin: 10px !important;
}
.card-group {
  margin-bottom: 50px;
}
.rightImg {
  background: url("./assets/images/team-img-right.png") no-repeat center center;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
  max-width: 100%;
}
.TeamHeader {
  background-image: url("./assets/images/background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
  padding: 100px 0 20px 0;
  margin-top: -140px;
}
.TeamHeader {
  height: auto;
}
.MuiGridList-root::-webkit-scrollbar {
  width: 0 !important;
  /* Remove scrollbar space */
  background: transparent !important;
  /* Optional: just make scrollbar invisible */
}
/*button*/

.btn-red {
	background: #bd0c0c!important;
	padding: 12px 20px!important;
	color: #ffffff!important;
	font-size: 18px!important;
	font-weight: 500!important;
	text-transform: capitalize!important;
	display: inline-block!important;
	border: 1px solid transparent!important;
	border-radius: 7px!important;
	-webkit-transition: 0.3s!important;
	-o-transition: 0.3s!important;
	transition: 0.3s!important;
}
.btn-red:focus {
  outline: none !important;
}
.btn-red:focus-visible {
  outline: none !important;
}
.btn-red:hover {
	background: transparent!important;
	border: 1px solid #bd0c0c!important;
	color: #bd0c0c!important;
}
.left {
  text-align: left;
}
.showcase h1 {
  font-weight: 700 !important;
  text-align: left;
}
.showcase h4,
.showcase h3 {
  font-weight: 700;
}
.showcase h4 a {
  color: unset !important;
}
.showcase h4 a:hover {
	color: #bd0c0c!important;
}
.showcase-right-text {
  margin-top: 20px;
  background: #DE7700;
  -webkit-box-shadow: 0px 5px 68px 0px rgba(0, 21, 100, 0.1);
  box-shadow: 0px 5px 68px 0px rgba(0, 21, 100, 0.1);
  padding: 35px 35px 0 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 35px;
}
.showcase .img-icon {
  margin-right: 20px;
  text-align: center;
}
.gallery span:hover {
  cursor: pointer !important;
}
/*about*/

.about-info-left {
	position: relative;
	background: #bd0c0c;
	border-radius: 5px;
	padding: 35px 25px 21px 30px;
	color: #ffffff;
	margin-top: -13%;
}
.about-info-right {
  padding: 50px 40px;
}
.inner-about-gallery .float-left {
  float: left;
  margin-right: 20px !important;
}
.inner-about-gallery {
  text-align: center;
}
.about.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  margin-top: 10px;
}
.full-width {
  position: relative !important;
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25% !important;
  margin-bottom: 10px;
}
.ContactHeader {
	background-image: url('./assets/images/DailySpec.png');
	height: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #ffffff!important;
	background-attachment: fixed;
	padding: 100px 0 20px 0;
	margin-top: -140px;
}
.ContactHeader {
  height: auto;
}
@media (min-width: 992px) {
  .ContactHeader {
    height: auto;
  }
}
.PhonerightImg {
	background: url('./assets/images/PhoneImg.png') no-repeat center center;
	-webkit-background-size: auto 100%;
	-moz-background-size: auto 100%;
	-o-background-size: auto 100%;
	background-size: auto 100%;
	max-width: 100%;
}
.blankspace {
  margin-top: 25px !important;
}
.MuiButtonBase-root.MuiButton-root {
	background-color: #bd0c0c;
}
.MuiButtonBase-root.MuiButton-root:active {
	background-color: #bd0c0c;
}
.MuiButtonBase-root.MuiButton-root:focus {
	background-color: #bd0c0c;
}
.MuiButtonBase-root.MuiButton-root:focus-visible {
	background-color: #bd0c0c;
}
.MuiButtonBase-root.MuiButton-root:hover {
	background-color: #bd0c0c;
	border: 1px solid #bd0c0c;
	color: #fff !important;
}
.DailySpec {
	background: url('./assets/images/DailySpec.png') no-repeat center center!important;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 100px 0 20px 0;
	margin-top: -140px;
}
img {
  max-width: 100%;
  width: auto\9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
.float-right {
  float: right;
}
.bold {
  font-weight: 900;
}
.MuiButton-root {
  color: rgba(250, 250, 250, 0.97) !important;
  border-radius: 3px !important;
  font-size: 1rem !important;
}
.MuiButtonBase-root.MuiButton-root {
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 24px;
	margin-bottom: 16px; */
}
.MuiButton-text {
  padding: 6px 8px !important;
}
.MuiButton-fullWidth {
  padding: 1rem 0.125rem !important;
}
.OrderHeader {
  background-image: url("./assets/images/ContactSliderImg.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
  padding: 100px 0 20px 0;
  margin-top: -140px;
}
.OrderHeader {
  height: auto;
}
@media (min-width: 992px) {
	.OrderHeader {
		height: auto;
	}
}
@media (max-width: 991px) {
	.OrderHeader .card {
		display: none;
	}
	.TeamHeader {
		height: auto;
	}
	.ContactHeader {
		height: auto;
	}
}
/*eloyalty*/

.div-margin-auto {
  margin: 0 auto;
}
/*eloyalty*/

.login {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: brown;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login .MuiPaper-elevation10 {
  box-shadow: unset !important;
  background: none;
}
.login .MuiButtonBase-root.MuiButton-root.btn-orange {
  background-color: #8B2000 !important;
}
.MuiButtonBase-root.MuiButton-root.btn-blue {
  background-color: unset !important;
  border: 1px solid #1976d2 !important;
  color: #1976d2 !important;
  font-weight: 550;
}
.login .MuiFormControl-root {
  background: #ffffff;
  margin-bottom: 10px;
}
.login .MuiInputBase-input {
  padding: 6px 10px 7px;
}
.login .MuiInputLabel-formControl {
  padding: 0 10px;
}
.horizontal p {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.horizontal p:before,
.horizontal p:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.horizontal p:before {
  margin-right: 10px;
}
.horizontal p:after {
  margin-left: 10px;
}
.item-container {
  display: inline-grid !important;
  grid-template-columns: auto 1fr !important;
}
@media (max-width: 970px) {
  .item-container {
    display: unset !important;
    grid-template-columns: unset !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTable .checkcell01 {
    display: none !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTable .checkcell02 {
    padding: 5px !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTable .checkcell03 {
    padding: 5px !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTable .checkcell04 {
    padding: 5px !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  .couponcode {
    min-width: 180px !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTotal .totalcell03 {
    display: none !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTotal .totalcell02 {
    padding: 5px !important;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
	#checkoutTotal .totalcell01 {
		padding: 5px !important;
		width: 150px !important;
	}
}
@media (min-width: 320px) and (max-width: 500px) {
  #checkoutTotal .totalcell04 {
    padding: 5px !important;
  }
}
#checkoutTotal .MuiButton-root {
  width: 80px !important;
}
/* MUI Input */
.MuiInputBase-input.Mui-disabled {
	background-color: #d9d9d9;
}
@media (max-width: 767px) {
	.footer-area .f-logo {
		margin: 0 auto !important;
		text-align: center!important;
	}
	.align-items-center .btn-red {
		transform: translateX(-50%);
		left: 50%;
		position: absolute;
	}	
	.footer-area hr {
		margin: 5rem 0 1rem 0 !important;
	}
}
@media (min-width: 320px) and (max-width: 600px) {
  .dept-front {
    padding: 10px 5px;
  }
  .dept-front img {
    width: 48px;
    margin-left: 35px;
  }
  .dept-front h3 {
      margin-left: 10px;
      font-size: 1.25rem !important;
  }
}
.dept-btn-cont a {
  text-decoration: none;
}